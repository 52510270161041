import React from "react";
import styled from "@emotion/styled/macro";
import { colors } from "@styles/ui_palette";
import { useForm } from "react-hook-form";
import { Axios, getSignResult, CaverOption } from "@utils/api";
import { Store } from "react-notifications-component";
import { rgba } from "emotion-rgba";
import { IAssetsDetail } from "@interface/assets";
import { prepare } from "klip-sdk";
import { encrypt, recover, getContractABI } from "@utils/help";
import { useChain } from "@utils/interact";
import { useRecoilState, useRecoilValue } from "recoil";
import { KlipKey, UserAddress, Chain } from "@recoil/auth";

// Components
import Input from "@components/common/ui/Input";

const Caver = require("caver-js");

type MarketsData = {
  wallet_address: string;
  blockchain: string;
  contract_address: string;
  token_id: string;
  is_display: number;
  quantity: number;
  is_end: number;
  uuid_market: string;
};

function MarektCancel() {
  const chainId = useRecoilValue(Chain);
  const [, setRequestKey] = useRecoilState<string>(KlipKey);
  const userAddress = useRecoilValue(UserAddress);
  const caver = new Caver(
    new Caver.providers.HttpProvider(
      "https://node-api.klaytnapi.com/v1/klaytn",
      CaverOption
    )
  );
  const notiOption = {
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm<MarketsData>({
    mode: "onChange",
    defaultValues: {
      blockchain: "klaytn",
      is_display: 0,
      is_end: 1,
      contract_address: process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS,
    },
  });

  const onSubmit = async (data: any) => {
    handleMarketCancel(data);
  };

  const handleMarketCancel = async (nft: IAssetsDetail) => {
    const token = `Bearer ${localStorage.getItem("token")}` || "";

    const reseponse = await prepare.signMessage({
      bappName: "Saio",
      value: "CancelMarket",
    });

    if (reseponse.request_key && reseponse.request_key !== "") {
      setRequestKey(reseponse.request_key);
      const timerId = setInterval(async () => {
        try {
          const { data: signData } = await getSignResult(reseponse.request_key);

          if (signData.result) {
            const { signature } = signData.result;
            const getRecover = await recover(
              "CancelMarket",
              signature,
              userAddress.address
            );
            const recoverPrams = encrypt(
              getRecover,
              process.env.REACT_APP_SIGNATURE_KEY
            );
            const formData = new FormData();

            formData.append("blockchain", "klaytn");
            formData.append("signature", signature);
            formData.append("message", "CancelMarket");
            formData.append("signature_param", recoverPrams);
            formData.append("is_display", "0");
            formData.append("is_end", "1");
            formData.append("contract_address", nft.contract_address);
            formData.append("wallet_address", nft.wallet_address);
            formData.append("token_id", nft.token_id);
            formData.append("quantity", nft.quantity.toString());
            formData.append("uuid_market", nft.uuid_market);

            clearInterval(timerId);
            setRequestKey("");

            try {
              const { success } = await Axios(
                "market-ecancel",
                formData,
                token
              );

              if (success) {
                await sendTransaction(nft.owner_address);
                Store.addNotification({
                  ...notiOption,
                  title: "완료",
                  message: "마켓이 취소 되었습니다.",
                  type: "default",
                  container: "top-left",
                  insert: "top",
                });

                window.location.reload();
              }
            } catch (error) {
              // 오류 메시지
              Store.addNotification({
                ...notiOption,
                title: "오류",
                message: "내용 한번 확인 후 이상있으면 개발팀에 문의해주세요.",
                type: "danger",
                container: "top-left",
                insert: "top",
              });
            }
          }
        } catch (error) {
          Store.addNotification({
            ...notiOption,
            title: "오류",
            message: "내용 한번 확인 후 이상있으면 개발팀에 문의해주세요.",
            type: "danger",
            container: "top-left",
            insert: "top",
          });
          console.log(error);
          setRequestKey("");
          clearInterval(timerId);
        }
      }, 1000);
    }
  };

  const sendTransaction = async (address: string) => {
    try {
      const senderPrivateKey = process.env.REACT_APP_FEE_PRIVATE_KEY;
      const keyring =
        caver.wallet.keyring.createFromPrivateKey(senderPrivateKey);
      caver.wallet.add(keyring);
      caver.klay.accounts.wallet.add(
        caver.klay.accounts.createWithAccountKey(
          keyring.address,
          keyring.key.privateKey
        )
      );
      const myContract = new caver.klay.Contract(
        getContractABI(useChain[chainId]).abi,
        process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS
      );

      const tx = await myContract.methods.deleteApprovalForAll(address).send({
        from: keyring.address,
        gas: 6000000,
      });

      return tx;
    } catch (error) {
      console.log(error);

      return error;
    }
  };

  return (
    <>
      <PageTitle>
        마켓 취소
        <PageSub>마켓리스트에서 사라지게 됩니다.</PageSub>
      </PageTitle>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Sections>
            <InputContainer>
              <Title>
                Owner 지갑주소
                <div>owner_address</div>
              </Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={
                  watch("wallet_address") ? watch("wallet_address").length : 0
                }
                getValue={getValues("wallet_address")}
                setValue={setValue}
                error={!!errors.wallet_address}
                placeholder="지갑주소를 입력해주세요."
                errorMessage="지갑주소를 입력해주세요."
                label="wallet_address"
                type="text"
              />
            </InputContainer>
            <InputContainer>
              <Title>
                블록체인
                <div>ex) klaytn</div>
              </Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={
                  watch("blockchain") ? watch("blockchain").length : 0
                }
                getValue={getValues("blockchain")}
                setValue={setValue}
                error={!!errors.blockchain}
                placeholder="블록체인를 입력해주세요. ex) klaytn"
                errorMessage="블록체인를 입력해주세요. ex) klaytn"
                label="blockchain"
                type="text"
              />
            </InputContainer>
          </Sections>
          <Sections>
            <InputContainer>
              <Title>컨트렉트</Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={
                  watch("contract_address")
                    ? watch("contract_address").length
                    : 0
                }
                getValue={getValues("contract_address")}
                setValue={setValue}
                error={!!errors.contract_address}
                placeholder="컨트렉트를 입력해주세요."
                errorMessage="컨트렉트를 입력해주세요."
                label="contract_address"
                type="text"
              />
            </InputContainer>
            <InputContainer>
              <Title>토큰 ID</Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={watch("token_id") ? watch("token_id").length : 0}
                getValue={getValues("token_id")}
                setValue={setValue}
                error={!!errors.token_id}
                placeholder="토큰 ID를 입력해주세요."
                errorMessage="토큰 ID를 입력해주세요."
                label="token_id"
                type="text"
              />
            </InputContainer>
          </Sections>
          <Sections>
            <InputContainer>
              <Title>
                is_display
                <div> 1 = 보임, 0 = 안보임</div>
              </Title>
              <Input
                register={register}
                required
                minLength={0}
                maxLength={100}
                valueLength={1}
                getValue={getValues("is_display")}
                setValue={setValue}
                error={!!errors.is_display}
                placeholder="0 or 1을 입력해주세요."
                errorMessage="0 or 1을 입력해주세요."
                label="is_display"
                type="number"
                disabled
              />
            </InputContainer>
            <InputContainer>
              <Title>
                is_end
                <div> 1 = 판매완료, 0 = 판매중</div>
              </Title>
              <Input
                register={register}
                required
                minLength={0}
                maxLength={100}
                valueLength={1}
                getValue={getValues("is_end")}
                setValue={setValue}
                error={!!errors.is_end}
                placeholder="0 or 1을 입력해주세요."
                errorMessage="0 or 1을 입력해주세요."
                label="is_end"
                type="number"
                disabled
              />
            </InputContainer>
          </Sections>
          <Sections>
            <InputContainer>
              <Title>
                수량
                <div>nft_markets table의 quantity</div>
              </Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={watch("quantity") ? watch("quantity") : 0}
                getValue={getValues("quantity")}
                setValue={setValue}
                error={!!errors.quantity}
                placeholder="수량을 입력해주세요."
                errorMessage="수량을 입력해주세요."
                label="quantity"
                type="number"
              />
            </InputContainer>
            <InputContainer>
              <Title>마켓 UUID</Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={
                  watch("uuid_market") ? watch("uuid_market").length : 0
                }
                getValue={getValues("uuid_market")}
                setValue={setValue}
                error={!!errors.uuid_market}
                placeholder="마켓 UUID를 입력해주세요."
                errorMessage="마켓 UUID를 입력해주세요."
                label="uuid_market"
                type="text"
              />
            </InputContainer>
          </Sections>
          <Submit type="submit" value="삭제하기" disabled={!isValid} />
        </Form>
      </Container>
    </>
  );
}

const Container = styled.div``;

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.Black100};
  margin-bottom: 20px;
`;

const PageSub = styled.div`
  font-size: 13px;
  color: ${colors.Black100};
  font-weight: 400;
  margin-top: 8px;
`;

const Form = styled.form``;

const Sections = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }
`;

const InputContainer = styled.div`
  margin: 10px 0;
  flex-basis: calc(50% - 10px);
`;

const Title = styled.div`
  font-size: 14px;
  color: ${rgba(colors.Black100, 0.9)};
  font-weight: 500;
  margin-bottom: 10px;
  height: 36px;

  & div {
    font-size: 12px;
    color: ${rgba(colors.Black100, 0.6)};
    margin-top: 5px;
  }
`;

const Submit = styled.input`
  margin: 32px 0;
  width: 100%;
  height: 58px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  background-color: ${colors.Black200};
  color: ${colors.White100};
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  &:disabled {
    background-color: ${colors.BlueGray300};
    color: ${colors.BlueGray700};
    cursor: not-allowed;
  }
`;
export default MarektCancel;
