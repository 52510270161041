import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";
import { colors } from "@styles/ui_palette";
import { useForm } from "react-hook-form";
import { Axios, getSignResult } from "@utils/api";
import { Store } from "react-notifications-component";
import { rgba } from "emotion-rgba";
import { IAssetsDetail } from "@interface/assets";
import { prepare } from "klip-sdk";
import { useRecoilState, useRecoilValue } from "recoil";
import { KlipKey, UserAddress } from "@recoil/auth";

// Components
import Input from "@components/common/ui/Input";

type MarketsData = {
  wallet_address: string;
  blockchain: string;

  video_url: string;
  video_time: string;
  video_title: string;
  video_valid_date: string;
  video_qty: number;
  asset_id: string;
};

function UpdateMovies() {
  const [nftAssets, setNftAssets] = useState<IAssetsDetail | null>(null);
  const [complete, setComplete] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [, setRequestKey] = useRecoilState<string>(KlipKey);
  const userAddress = useRecoilValue(UserAddress);
  const notiOption = {
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm<MarketsData>({
    mode: "onChange",
    defaultValues: {
      blockchain: "klaytn",
      wallet_address: userAddress.address,
    },
  });

  const onSubmit = async (data: any) => {
    handleMarketCancel(data);
  };

  const handleAssets = useCallback(async () => {
    setNftAssets(null);
    setLoading(true);
    const token = `Bearer ${localStorage.getItem("token")}` || "";

    try {
      const { data } = await Axios(
        `assetdetail/${getValues("asset_id")}`,
        token
      );
      const getData: IAssetsDetail[] | null =
        Object.values(data.assetList_data) || null;

      if (getData) {
        const nftDetail = getData.filter(
          (_market, index) => index === getData.length - 1
        );

        const newNftDetail = nftDetail.map((markets) => {
          const newMarket = markets;

          return newMarket;
        });

        setComplete(true);

        if (newNftDetail[0].video_title) {
          setValue("video_title", newNftDetail[0].video_title);
          setValue("video_valid_date", newNftDetail[0].video_valid_date);
          setValue("video_time", newNftDetail[0].video_time);
          setValue("video_qty", newNftDetail[0].video_qty);
          setValue("video_url", newNftDetail[0].video_url);
        }
      }
    } catch (error) {
      // 오류 메시지
      Store.addNotification({
        ...notiOption,
        title: "오류",
        message: "내용 한번 확인 후 이상있으면 개발팀에 문의해주세요.",
        type: "danger",
        container: "top-left",
        insert: "top",
      });
      setComplete(false);
    } finally {
      setLoading(false);
    }
  }, [nftAssets, setNftAssets, setLoading]);

  const handleMarketCancel = async (nft: IAssetsDetail) => {
    const token = `Bearer ${localStorage.getItem("token")}` || "";

    console.log(nft);

    const reseponse = await prepare.signMessage({
      bappName: "Saio",
      value: "UpdateMovies",
    });

    if (reseponse.request_key && reseponse.request_key !== "") {
      setRequestKey(reseponse.request_key);
      const timerId = setInterval(async () => {
        try {
          const { data: signData } = await getSignResult(reseponse.request_key);

          if (signData.result) {
            const formData = new FormData();

            formData.append("wallet_address", userAddress.address);
            formData.append("blockchain", "klaytn");

            formData.append("video_url", nft.video_url);
            formData.append("video_time", nft.video_time);
            formData.append("video_title", nft.video_title);
            formData.append("video_valid_date", nft.video_valid_date);
            formData.append("video_qty", nft.video_qty.toString());
            formData.append("asset_id", nft.asset_id.toString());

            clearInterval(timerId);
            setRequestKey("");

            try {
              const { success } = await Axios("video_update", formData, token);

              if (success) {
                Store.addNotification({
                  ...notiOption,
                  title: "완료",
                  message: "상영관 상품으로 업데이트 되었습니다.",
                  type: "default",
                  container: "top-left",
                  insert: "top",
                });

                window.location.reload();
              }
            } catch (error) {
              // 오류 메시지
              Store.addNotification({
                ...notiOption,
                title: "오류",
                message: "내용 한번 확인 후 이상있으면 개발팀에 문의해주세요.",
                type: "danger",
                container: "top-left",
                insert: "top",
              });
            }
          }
        } catch (error) {
          Store.addNotification({
            ...notiOption,
            title: "오류",
            message: "내용 한번 확인 후 이상있으면 개발팀에 문의해주세요.",
            type: "danger",
            container: "top-left",
            insert: "top",
          });
          console.log(error);
          setRequestKey("");
          clearInterval(timerId);
        }
      }, 1000);
    }
  };

  return (
    <>
      <PageTitle>
        상영관 상품 업데이트
        <PageSub>일반상품을 상영관 상품으로 업데이트 하는 곳입니다.</PageSub>
      </PageTitle>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Sections>
            <InputContainer>
              <Title>
                <span>상품 ID</span>
                <Button onClick={handleAssets} disabled={isLoading}>
                  조회
                </Button>
              </Title>
              <Input
                register={register}
                required
                minLength={1}
                maxLength={100}
                valueLength={watch("asset_id") ? watch("asset_id").length : 0}
                getValue={getValues("asset_id")}
                setValue={setValue}
                error={!!errors.asset_id}
                placeholder="상품 ID를 입력해주세요."
                errorMessage="상품 ID를 입력해주세요."
                label="asset_id"
                type="text"
              />
            </InputContainer>
          </Sections>
          {complete && (
            <>
              <Sections>
                <InputContainer>
                  <Title>비디오 주소</Title>
                  <Input
                    register={register}
                    required
                    minLength={1}
                    maxLength={100}
                    valueLength={
                      watch("video_url") ? watch("video_url").length : 0
                    }
                    getValue={getValues("video_url")}
                    setValue={setValue}
                    error={!!errors.video_url}
                    placeholder="비디오 주소를 입력해주세요."
                    errorMessage="비디오 주소를 입력해주세요."
                    label="video_url"
                    type="text"
                  />
                </InputContainer>
                <InputContainer>
                  <Title>상영시간</Title>
                  <Input
                    register={register}
                    required
                    minLength={1}
                    maxLength={100}
                    valueLength={
                      watch("video_time") ? watch("video_time").length : 0
                    }
                    getValue={getValues("video_time")}
                    setValue={setValue}
                    error={!!errors.video_time}
                    placeholder="상영시간을 입력해주세요."
                    errorMessage="상영시간을 입력해주세요."
                    label="video_time"
                    type="text"
                  />
                </InputContainer>
              </Sections>
              <Sections>
                <InputContainer>
                  <Title>비디오 제목</Title>
                  <Input
                    register={register}
                    required
                    minLength={1}
                    maxLength={100}
                    valueLength={
                      watch("video_title") ? watch("video_title").length : 0
                    }
                    getValue={getValues("video_title")}
                    setValue={setValue}
                    error={!!errors.video_title}
                    placeholder="비디오 제목을 입력해주세요."
                    errorMessage="비디오 제목을 입력해주세요."
                    label="video_title"
                    type="text"
                  />
                </InputContainer>
                <InputContainer>
                  <Title>유효기간</Title>
                  <Input
                    register={register}
                    required
                    minLength={1}
                    maxLength={100}
                    valueLength={
                      watch("video_valid_date")
                        ? watch("video_valid_date").length
                        : 0
                    }
                    getValue={getValues("video_valid_date")}
                    setValue={setValue}
                    error={!!errors.video_valid_date}
                    placeholder="유효기간 입력해주세요."
                    errorMessage="유효기간 입력해주세요."
                    label="video_valid_date"
                    type="text"
                  />
                </InputContainer>
              </Sections>
              <Sections>
                <InputContainer>
                  <Title>가능수량</Title>
                  <Input
                    register={register}
                    required
                    minLength={0}
                    maxLength={100}
                    valueLength={watch("video_qty") ? watch("video_qty") : 0}
                    getValue={getValues("video_qty")}
                    setValue={setValue}
                    error={!!errors.video_qty}
                    placeholder="가능수량을 입력해주세요."
                    errorMessage="가능수량을 입력해주세요."
                    label="video_qty"
                    type="number"
                  />
                </InputContainer>
              </Sections>
              <Submit
                type="submit"
                value="상영관 업데이트"
                disabled={!isValid}
              />
            </>
          )}
        </Form>
      </Container>
    </>
  );
}

const Container = styled.div``;

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.Black100};
  margin-bottom: 20px;
`;

const PageSub = styled.div`
  font-size: 13px;
  color: ${colors.Black100};
  font-weight: 400;
  margin-top: 8px;
`;

const Form = styled.form``;

const Sections = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }
`;

const InputContainer = styled.div`
  margin: 10px 0;
  flex-basis: calc(50% - 10px);
`;

const Title = styled.div`
  font-size: 14px;
  color: ${rgba(colors.Black100, 0.9)};
  font-weight: 500;
  margin-bottom: 10px;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  display: flex;

  & div {
    font-size: 12px;
    color: ${rgba(colors.Black100, 0.6)};
    margin-top: 5px;
  }
`;

const Button = styled.button`
  width: 75px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid ${colors.BlueGray600};
  border-radius: 8px;
  background-color: ${colors.White100};
  color: ${colors.Black200};
  cursor: pointer;
`;

const Submit = styled.input`
  margin: 32px 0;
  width: 100%;
  height: 58px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  background-color: ${colors.Black200};
  color: ${colors.White100};
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

  &:disabled {
    background-color: ${colors.BlueGray300};
    color: ${colors.BlueGray700};
    cursor: not-allowed;
  }
`;
export default UpdateMovies;
