import React from "react";
import styled from "@emotion/styled/macro";
import { colors } from "@styles/ui_palette";

function Home() {
  return (
    <Container>
      <ContractAddress
        onClick={() =>
          window.open(
            `https://scope.klaytn.com/account/${process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS}`
          )
        }
      >
        <span>Contract Address:</span>{" "}
        {process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS}
      </ContractAddress>
      <PanelsBox>
        <Panels onClick={() => window.open("https://saioplay.com")}>
          <Icons width={200} src="/img/layout/header/ic_brand_logo.png" />
          사이오 플레이 바로가기
        </Panels>
        {/* <Panels
          onClick={() =>
            window.open("https://conuts.zendesk.com/agent/dashboard")
          }
        >
          <Icons width={100} src="/img/home/img_zendesk.svg" />
          젠데스크
        </Panels> */}
        <Panels
          onClick={() => window.open("https://app.tosspayments.com/signin")}
        >
          <Icons width={200} src="/img/home/img_toss.png" />
          토스 페이먼츠
        </Panels>
        <Panels onClick={() => window.open("https://scope.klaytn.com/")}>
          <Icons width={150} src="/img/home/img_klaytn.png" />
          클레이튼 스코프
        </Panels>
        <Panels
          onClick={() => window.open("https://admin.iamport.kr/auth/signin")}
        >
          <Icons width={90} src="/img/home/img_import.png" />
          I&apos;m Port (휴대폰 인증 서비스)
        </Panels>
        {/* <Panels onClick={() => window.open('https://analytics.google.com/analytics/web/?hl=ko#/')}>
                    <Icons
                        width={100}
                        src="/img/home/img_google.png"
                    />
                    구글 애널리틱스
                </Panels> */}
      </PanelsBox>
    </Container>
  );
}

const Container = styled.div``;

const PanelsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Panels = styled.div`
  flex-basis: calc(50% - 10px);
  background-color: ${colors.White100};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  min-height: 100px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 20px;

  &:hover {
    text-decoration: underline;
    background-color: ${colors.BlueGray300};
  }
`;

const Icons = styled("img")<{ width: number }>`
  width: ${(props) => `${props.width})px`};
  margin-right: 8px;
`;

const ContractAddress = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 400;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export default Home;
