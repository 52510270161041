import React from "react";
import styled from "@emotion/styled/macro";
import { colors } from "@styles/ui_palette";
import { useForm } from "react-hook-form";
import { getSignResult, CaverOption } from "@utils/api";
import { Store } from "react-notifications-component";
import { rgba } from "emotion-rgba";
import { IAssetsDetail } from "@interface/assets";
import { prepare } from "klip-sdk";
import { getContractABI } from "@utils/help";
import { useChain } from "@utils/interact";
import { useRecoilState, useRecoilValue } from "recoil";
import { KlipKey, Chain } from "@recoil/auth";

// Components
import Input from "@components/common/ui/Input";

const Caver = require("caver-js");

type MarketsData = {
  wallet_address: string;
  blockchain: string;
  contract_address: string;
  token_id: string;
  owner_address: string;
  quantity: number;
  market_id: number;
  tx_id: string;
  metadata_url: string;
  uuid_market: string;
};

function TokenManagements() {
  const chainId = useRecoilValue(Chain);
  const [, setRequestKey] = useRecoilState<string>(KlipKey);
  const caver = new Caver(
    new Caver.providers.HttpProvider(
      "https://node-api.klaytnapi.com/v1/klaytn",
      CaverOption
    )
  );
  const notiOption = {
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { isValid, errors },
  } = useForm<MarketsData>({
    mode: "onChange",
    defaultValues: {
      blockchain: "klaytn",
      contract_address: process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS,
    },
  });

  const onSubmit = async (data: any) => {
    handleMarketCancel(data);
  };

  const handleMarketCancel = async (nft: IAssetsDetail) => {
    const reseponse = await prepare.signMessage({
      bappName: "Saio",
      value: "TokenManagements",
    });

    if (reseponse.request_key && reseponse.request_key !== "") {
      setRequestKey(reseponse.request_key);
      const timerId = setInterval(async () => {
        try {
          const { data: signData } = await getSignResult(reseponse.request_key);

          if (signData.result) {
            const txId = await sendTransaction(nft);

            if (txId) {
              Store.addNotification({
                ...notiOption,
                title: "완료",
                message: "트렌젝션 복구가 완료되었습니다.",
                type: "default",
                container: "top-left",
                insert: "top",
              });

              window.location.reload();
            }
          }
        } catch (error) {
          Store.addNotification({
            ...notiOption,
            title: "오류",
            message: "내용 한번 확인 후 이상있으면 개발팀에 문의해주세요.",
            type: "danger",
            container: "top-left",
            insert: "top",
          });
          console.log(error);
          setRequestKey("");
          clearInterval(timerId);
        }
      }, 1000);
    }
  };

  const sendTransaction = async (assets: IAssetsDetail) => {
    const senderPrivateKey = process.env.REACT_APP_FEE_PRIVATE_KEY;
    const keyring = caver.wallet.keyring.createFromPrivateKey(senderPrivateKey);
    caver.wallet.add(keyring);
    caver.klay.accounts.wallet.add(
      caver.klay.accounts.createWithAccountKey(
        keyring.address,
        keyring.key.privateKey
      )
    );
    const myContract = new caver.klay.Contract(
      getContractABI(useChain[chainId]).abi,
      process.env.REACT_APP_KLAYTN_CONTRACT_ADDRESS
    );

    const tx = await myContract.methods
      .setKRWOrderRestore(
        assets.uuid_market,
        assets.token_id,
        assets.metadata_url,
        assets.quantity,
        false,
        assets.wallet_address
      )
      .send({
        from: keyring.address,
        gas: 6000000,
      });

    return tx.transactionHash;
  };

  return (
    <>
      <PageTitle>
        코넛 코인 관리
        <PageSub>
          이더리움 기반의 CONUT 코인 관리 페이지 입니다. <br />
          메타마스크를 연결해야 이용 가능합니다.
        </PageSub>
      </PageTitle>
      <Container>
        <DeployButton>CNUT 코인 발행하기</DeployButton>
      </Container>
    </>
  );
}

const Container = styled.div``;

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.Black100};
  margin-bottom: 20px;
`;

const PageSub = styled.div`
  font-size: 13px;
  color: ${colors.Black100};
  font-weight: 400;
  margin-top: 8px;
`;

const DeployButton = styled.button`
  width: 500px;
  height: 50px;
  background: ${colors.Black100};
  color: ${colors.White100};
  font-size: 16px;
  border: 0;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default TokenManagements;
