import React, { useEffect, useCallback } from "react";
import styled from "@emotion/styled/macro";
import qs from "qs";
import { useRecoilState } from "recoil";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { colors } from "@styles/ui_palette";
import { Axios, CaverOption } from "@utils/api";
import { checkUserInfo } from "@utils/help";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Logout } from "@utils/interact";
import { useTranslation } from "react-i18next";

// Components
import QrModal from "@components/layout/QrModal";

// recoil
import { UserAddress, LocalseType, KlipKey } from "@recoil/auth";
import { rgba } from "emotion-rgba";

const Caver = require("caver-js");

function Layouts() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const caver = new Caver(
    new Caver.providers.HttpProvider(
      "https://node-api.klaytnapi.com/v1/klaytn",
      CaverOption
    )
  );
  const [userAddress, setUserAddress] = useRecoilState(UserAddress);
  const [requestKey] = useRecoilState<string>(KlipKey);
  const [, setLocaleType] = useRecoilState(LocalseType);

  // 새로고침시 로그인 여부 체크하는 함수
  const checkLogin = useCallback(async () => {
    const getLocalStorage = localStorage.getItem("loginState");
    const localeLocaltroage = localStorage.getItem("locale");

    // 로컬 스토리지 체크
    if (localeLocaltroage) {
      setLocaleType(localeLocaltroage);
      i18n.changeLanguage(localeLocaltroage);
    }

    if (getLocalStorage) {
      // 로컬스토리지 데이터가 있으면 JSON형식으로 파싱
      const loginState = qs.parse(getLocalStorage, {
        ignoreQueryPrefix: true,
      });

      // 로그인 세션 6시간 체크
      if (Number(loginState.expire) < Date.now()) {
        Logout(t);
        return;
      }

      // 로컬스토리지 데이터에 지갑주소가 없으면 함수 return
      if (!loginState.wallet_address) {
        return;
      }
      // 지갑 잔액 가져오기
      const balance = await caver.klay.getBalance(loginState.wallet_address);

      // LOGIN API
      try {
        const { data } = await Axios("register", getLocalStorage);

        localStorage.setItem("loginState", getLocalStorage);
        localStorage.setItem("token", data.token);
        const isCheckUserInfo = checkUserInfo(data);

        // 로그인 데이터 Recoil에 저장해준다.
        setUserAddress({
          address: loginState.wallet_address.toString().toLowerCase(),
          status: "succese",
          balance: caver.utils.convertFromPeb(balance, "KLAY"),
          token: data.token,
          user_name: data.user_name,
          user_email: data.user_email,
          image_profile: data.image_profile,
          vip_gb: data.vip_gb.toString(),
          shipping_name: data.shipping_name,
          phone_num: data.phone_num,
          shipping_addr: data.shipping_addr,
          isCertification: isCheckUserInfo,
          adult: data.adult,
          birth: data.birth,
          name: data.name,
          foreigner: data.foreigner,
          gender: data.gender,
        });
      } catch (error) {
        Store.addNotification({
          title: t("Notification.errorTitle"),
          message: t("Notification.tryMessage"),
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      }
    } else {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    // 가장먼저 실행하는 함수
    checkLogin();
  }, []);

  return (
    <LayoutBlock>
      <ContentsBox>
        {location.pathname !== "/login" && (
          <SideMenu>
            <StickyBox>
              <AdminLogo>
                <LogoBox>
                  <Logo src="/img/layout/header/ic_brand_logo.png" />
                  <ServerType>
                    {process.env.REACT_APP_MODE === "development"
                      ? "개발서버"
                      : "운영서버"}
                  </ServerType>
                </LogoBox>
              </AdminLogo>
              <MenuItems onClick={() => navigate("/")}>Dashbord</MenuItems>
              <MenuItems onClick={() => navigate("/market-cancel")}>
                Market Cancel
              </MenuItems>
              <MenuItems onClick={() => navigate("/market-restore")}>
                Market Restore
              </MenuItems>
              <MenuItems onClick={() => navigate("/airdrop")}>
                Airdrop
              </MenuItems>
              <MenuItems onClick={() => navigate("/transaction-restore")}>
                Transaction Restore
              </MenuItems>
              <MenuItems onClick={() => navigate("/token-managements")}>
                Token Managements
              </MenuItems>
              <MenuItems onClick={() => navigate("/update-movies")}>
                Update Movies
              </MenuItems>
            </StickyBox>
          </SideMenu>
        )}

        {/* BODY 영역 */}
        <BodySection isLogin={location.pathname !== "/login"}>
          <BodyHeader>
            Saio Play 관리자 모드
            {userAddress.address !== "" && (
              <UserInformatino>
                <UserBox>
                  <UserProfile src={userAddress.image_profile} />
                  <UserName>{userAddress.user_name}</UserName>
                </UserBox>
                <LogoutButton onClick={() => Logout(t)}>로그아웃</LogoutButton>
              </UserInformatino>
            )}
          </BodyHeader>

          <ReactNotifications />
          {userAddress.address && userAddress.vip_gb !== "3" && (
            <BodyContents isLogin={location.pathname !== "/login"}>
              <RequestCertification>
                관리자의 권한을 요청해 주세요.
              </RequestCertification>
            </BodyContents>
          )}
          {(userAddress.address === "" || userAddress.vip_gb === "3") && (
            <BodyContents isLogin={location.pathname !== "/login"}>
              <Outlet />
            </BodyContents>
          )}
        </BodySection>
      </ContentsBox>

      {requestKey !== "" && <QrModal />}
    </LayoutBlock>
  );
}

const LayoutBlock = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContentsBox = styled.div`
  display: flex;
`;

const SideMenu = styled.div`
  flex-basis: 300px;
  width: 300px;
  background-color: ${colors.Black100};
  position: relative; ;
`;

const StickyBox = styled.div`
  position: sticky;
  left: 0;
  top: 0;
`;

const MenuItems = styled.div`
  color: ${colors.White100};
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${rgba("#FAAA2D", 0.8)};
  }
`;

const BodySection = styled.div<{ isLogin: boolean }>`
  flex-basis: ${(props) => (props.isLogin ? "calc(100% - 300px)" : "100%")};
  width: ${(props) => (props.isLogin ? "calc(100% - 300px)" : "100%")};
  background-color: ${colors.BlueGray400};
  position: relative;
  min-height: 100vh;
`;

const BodyContents = styled.div<{ isLogin: boolean }>`
  padding: 20px;
  width: 1024px;
  margin: ${(props) => (props.isLogin ? 0 : "0 auto")};
`;

const BodyHeader = styled.div`
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.White100};
  position: sticky;
  left: 0;
  top: 0;
  z-index: 90;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
`;

const AdminLogo = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f5286e -5.38%, #faaa2d 116.92%);
`;

const Logo = styled("img")`
  width: 200px;
`;

const ServerType = styled.div`
  margin-top: 10px;
  color: ${colors.Black200};
`;

const LogoBox = styled.div`
  text-align: center;
`;

const LogoutButton = styled.button`
  background-color: ${colors.White100};
  height: 38px;
  width: 100px;
  border: 1px solid ${colors.BlueGray500};
  outline: 0;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 20px;

  &:hover {
    background-color: ${colors.BlueGray300};
  }
`;

const UserInformatino = styled.div`
  display: flex;
  align-items: center;
`;

const UserProfile = styled("img")`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${colors.BlueGray500};
`;

const UserBox = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  color: ${colors.Black200};
  margin-left: 10px;
`;

const RequestCertification = styled.div`
  font-size: 16px;
`;

export default Layouts;
